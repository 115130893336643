import Login from 'views/examples/Login.js';
import { ChargesPage } from 'pages/charges/index';
import { ImportsPage } from 'pages/imports/index';
import { UsersPage } from 'pages/users/index';

const routes = [
	{
		path: '/charges',
		name: 'Transferências',
		icon: 'fas fa-money-bill text-red',
		component: <ChargesPage />,
		layout: '/admin',
		role: 'user'
	},
	{
		path: '/imports',
		name: 'Importações',
		icon: 'fas fa-list text-red',
		component: <ImportsPage />,
		layout: '/admin',
		role: 'user'
	},
	{
		path: '/users',
		name: 'Usuários',
		icon: 'fas fa-user text-red',
		component: <UsersPage />,
		layout: '/admin',
		role: 'admin'
	},
	{
		path: '/login',
		name: 'Login',
		icon: 'fas fa-key text-info',
		component: <Login />,
		layout: '/auth'
	}
];

export default routes;
