import { toast } from 'react-toastify';

export const useToast = () => {
	const success = message => {
		return toast.success(message);
	};

	const error = message => {
		return toast.error(message);
	};

	const info = message => {
		return toast.info(message);
	};

	return {
		toast: {
			success,
			error,
			info
		}
	};
};
