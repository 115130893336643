import { UsersReport } from 'components/Reports/Users';
import { ReportContextProvider } from 'shared/components/reports/context';
import { AclResource } from 'shared/constants/user';

export const UsersPage = () => {
	return (
		<ReportContextProvider
			url="/reports/users"
			acl={{
				view: AclResource.USERS_VIEW,
				export: AclResource.USERS_EXPORT
			}}
		>
			<UsersReport />
		</ReportContextProvider>
	);
};
