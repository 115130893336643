import { getChargeStatus } from 'components/Reports/Charges/constants';
import dayjs from 'dayjs';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

export const ChargeDetailsModal = forwardRef((_, ref) => {
	const [data, setData] = useState(null);

	const [isOpen, setIsOpen] = useState(false);

	const show = props => {
		setData(props);
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({
		show,
		close
	}));

	if (!data) return;

	return (
		<Modal isOpen={isOpen}>
			<ModalBody>
				<div className="d-flex align-items-center justify-content-between mb-4">
					<h3>Detalhes da cobrança</h3>
					<button type="button" onClick={close} className="btn">
						<i className="fas fa-times" />
					</button>
				</div>

				<div className="row">
					<div className="col-12 mb-3">
						<span className="d-block font-weight-bold">
							Pagador
						</span>
						{data.payer ? (
							<>
								<span className="d-block">
									Nome: {data.payer.name}
								</span>
								<span className="d-block">
									Documento: {data.payer.document}
								</span>
							</>
						) : (
							<span className="d-block">Não informado</span>
						)}
					</div>

					<div className="col-md-6 my-3">
						<span className="d-block font-weight-bold">Valor</span>
						{(data.amount / 100).toLocaleString('pt-br', {
							style: 'currency',
							currency: 'BRL'
						})}
					</div>

					<div className="col-md-6 my-3">
						<span className="d-block font-weight-bold">PIX</span>
						{data.pix}
					</div>

					<div className="col-md-6 mt-3">
						<span className="d-block font-weight-bold">Data</span>
						{dayjs(data.chargeDate).format('DD/MM/YYYY')}
					</div>

					<div className="col-md-6 mt-3">
						<span className="d-block font-weight-bold">Status</span>
						<span className={getChargeStatus(data.status).color}>
							<i
								className={`d-inline-block mr-1 ${
									getChargeStatus(data.status).icon
								}`}
							/>
							{getChargeStatus(data.status).label}
						</span>
					</div>
				</div>
			</ModalBody>
			<ModalFooter
				className="d-flex align-items-center justify-content-end"
				style={{ gap: '8px' }}
			>
				<Button type="button" color="secondary" onClick={close}>
					Fechar
				</Button>
			</ModalFooter>
		</Modal>
	);
});
