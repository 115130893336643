import { useSearchParams } from 'react-router-dom';
import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Label,
	Row
} from 'reactstrap';
import { AccessDenied } from 'shared/components/AccessDenied';
import { Checkbox } from 'shared/components/Form/Checkbox';
import { AclResource } from 'shared/constants/user';
import { useAcl } from 'shared/hooks/acl';
import { resources } from './permissions';
import { useUserForm } from 'modules/users/edit/hooks/user-form';

export const EditUserPage = () => {
	const [search] = useSearchParams();
	const { isPermitted } = useAcl();

	const id = search.get('id');

	const {
		handleSubmit,
		isLoading,
		isSaving,
		name,
		setName,
		email,
		setEmail,
		setPassword,
		acl,
		setAcl
	} = useUserForm(id);

	if (!isPermitted(AclResource.USERS_MANAGE)) return <AccessDenied />;

	const handleCheckboxChange = e => {
		const { value, checked } = e.target;

		if (checked) {
			setAcl(prev => [...prev, value]);
			return;
		}

		setAcl(prev => prev.filter(item => item !== value));
	};

	return (
		<Container style={{ padding: '6rem 0' }} fluid>
			<Row>
				<div className="col">
					<form onSubmit={handleSubmit}>
						<Card>
							<CardHeader className="border-0 d-flex align-items-center justify-content-between">
								<Breadcrumb listClassName="bg-white px-0 mb-0">
									<BreadcrumbItem>
										<a
											href="/admin/users"
											className="font-weight-bold"
										>
											Usuários
										</a>
									</BreadcrumbItem>
									<BreadcrumbItem active>
										<span className="font-weight-bold">
											{id ? 'Atualizar' : 'Cadastrar'}{' '}
											usuário
										</span>
									</BreadcrumbItem>
								</Breadcrumb>
							</CardHeader>
							<CardBody>
								<Row>
									<Col md="6" className="border-right">
										<h3 className="h3 mb-3">
											Dados pessoais
										</h3>
										<FormGroup className="mb-3">
											<Label>Nome*</Label>
											<Input
												disabled={isLoading}
												required
												placeholder="Nome"
												type="text"
												value={name}
												onChange={e =>
													setName(e.target.value)
												}
											/>
										</FormGroup>

										<FormGroup className="mb-3">
											<Label>Email*</Label>
											<Input
												disabled={isLoading}
												required
												placeholder="Email"
												type="email"
												value={email}
												onChange={e =>
													setEmail(e.target.value)
												}
											/>
										</FormGroup>

										<FormGroup className="mb-3">
											<Label>Senha{id ? '' : '*'}</Label>
											<Input
												disabled={isLoading}
												required={!id}
												placeholder="Senha"
												type="password"
												onChange={e =>
													setPassword(e.target.value)
												}
											/>
										</FormGroup>
									</Col>

									<Col md="6">
										<h3 className="h3 mb-3">Permissões</h3>

										<div
											className="d-flex flex-column"
											style={{ gap: '1rem' }}
										>
											{resources.map(category => (
												<div
													key={category.name}
													className="d-flex flex-column"
													style={{ gap: '.6rem' }}
												>
													<h4>{category.name}</h4>
													{category.resources.map(
														resource => (
															<Checkbox
																key={
																	resource.resource
																}
																id={
																	resource.resource
																}
																value={
																	resource.resource
																}
																name="acl"
																label={
																	resource.name
																}
																onChange={
																	handleCheckboxChange
																}
																checked={acl.includes(
																	resource.resource
																)}
																disabled={
																	isLoading
																}
															/>
														)
													)}
												</div>
											))}
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter className="d-flex justify-content-end">
								<Button
									disabled={isSaving}
									color="primary"
									size="md"
									type="submit"
								>
									Salvar
								</Button>
							</CardFooter>
						</Card>
					</form>
				</div>
			</Row>
		</Container>
	);
};
