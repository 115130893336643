import axios from 'axios';

export const getHttpClient = () => {
	const auth = localStorage.getItem('access_token');

	return axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: {
			Authorization: `Bearer ${auth}`
		}
	});
};
