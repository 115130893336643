import * as jose from 'jose';

export const getUser = () => {
	const token = localStorage.getItem('access_token');

	if (!token) return null;

	const jwt = jose.decodeJwt(token);

	const isExpired = jwt.exp < Date.now() / 1000;

	if (isExpired) {
		localStorage.removeItem('access_token');
		return null;
	}

	return new User(jwt);
};

class User {
	constructor(props) {
		this.name = props.user.name;
		this.email = props.user.email;
		this.acl = props.user.acl;
	}

	isPermitted = resource => {
		return this.acl.includes(resource);
	};
}
