export const Checkbox = props => {
	return (
		<div className="custom-control custom-control-alternative custom-checkbox">
			<input
				className="custom-control-input"
				id={props.id}
				type="checkbox"
				value={props.value}
				name={props.name}
				onChange={props.onChange}
				checked={props.checked}
			/>
			<label className="custom-control-label" htmlFor={props.id}>
				<span className="text-muted">{props.label}</span>
			</label>
		</div>
	);
};
