import { MobileTimePicker } from '@mui/x-date-pickers';
import { forwardRef } from 'react';

export const TimePicker = forwardRef((props, ref) => {
	return (
		<MobileTimePicker
			{...props}
			inputRef={ref}
			views={['hours', 'minutes']}
		/>
	);
});
