export const AclResource = {
	CHARGES_ARCHIVE: 'CHARGES_ARCHIVE',
	CHARGES_CHARGE: 'CHARGES_CHARGE',
	CHARGES_CREATE: 'CHARGES_CREATE',
	CHARGES_EXPORT: 'CHARGES_EXPORT',
	CHARGES_IMPORT: 'CHARGES_IMPORT',
	CHARGES_VIEW_ALL: 'CHARGES_VIEW_ALL',
	CHARGES_VIEW_OWN: 'CHARGES_VIEW_OWN',
	IMPORT_REQUESTS_EXPORT: 'IMPORT_REQUESTS_EXPORT',
	IMPORT_REQUESTS_VIEW: 'IMPORT_REQUESTS_VIEW',
	USERS_EXPORT: 'USERS_EXPORT',
	USERS_MANAGE: 'USERS_MANAGE',
	USERS_VIEW: 'USERS_VIEW'
};
