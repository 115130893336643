import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	Nav,
	Container,
	Media
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { getUser } from 'shared/utils/user';

const AdminNavbar = () => {
	const navigate = useNavigate();

	const handleLogout = () => {
		localStorage.removeItem('access_token');
		navigate('/');
	};

	const user = getUser();

	return (
		<Navbar className="navbar-top" expand="md" id="navbar-main">
			<Container fluid className="justify-content-end">
				<Nav className="align-items-center d-none d-md-flex" navbar>
					<UncontrolledDropdown nav>
						<DropdownToggle className="pr-0" nav>
							<Media className="align-items-center">
								<span className="avatar avatar-sm rounded-circle bg-white">
									<img
										alt="..."
										src={require('../../assets/img/user.png')}
									/>
								</span>
								<Media className="ml-2 d-none d-lg-block">
									<span className="mb-0 text-sm font-weight-bold">
										{user.name}
									</span>
								</Media>
							</Media>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>
							<DropdownItem href="#" onClick={handleLogout}>
								<i className="ni ni-user-run" />
								<span>Sair</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
			</Container>
		</Navbar>
	);
};

export default AdminNavbar;
