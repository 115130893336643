import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import {
	forwardRef,
	useContext,
	useImperativeHandle,
	useRef,
	useState
} from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { getHttpClient } from 'shared/api';
import { TimePicker } from 'shared/components/Form/TimePicker';
import { ReportContext } from 'shared/components/reports/context';

export const DoChargeModal = forwardRef(({ setCharges }, ref) => {
	const { refresh } = useContext(ReportContext);
	const [batch, setBatch] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const [isCharging, setIsCharging] = useState(false);
	const dateInput = useRef(null);
	const timeInput = useRef(null);

	const show = batch => {
		setBatch(batch);
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
		setCharges([]);
		setBatch(null);
	};

	useImperativeHandle(ref, () => ({
		show,
		close
	}));

	const getSchedule = () => {
		const date = dateInput.current.valueAsDate;
		if (!date) return null;

		const libDate = dayjs(date).add(3, 'h');

		const time = timeInput.current.value;
		const [hour, minutes] = time.split(':');

		return libDate
			.hour(hour || 7)
			.minute(minutes || 30)
			.toDate();
	};

	const handleDoCharge = async () => {
		try {
			const httpClient = getHttpClient();
			const schedule = getSchedule();

			if (!batch) return;

			setIsCharging(true);

			await httpClient.post('/batch/mark-charges', {
				batch,
				data: { scheduledTo: schedule }
			});

			toast.success(
				'Cobranças colocadas na fila de processamento com sucesso!'
			);

			setCharges([]);
			close();
			refresh();
		} catch (e) {
			console.error(e);

			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao cobrar, tente novamente mais tarde.'
			);
		} finally {
			setIsCharging(false);
		}
	};

	return (
		<Modal isOpen={isOpen}>
			<ModalBody>
				<div className="d-flex align-items-center justify-content-between mb-3">
					<h3>Realizar cobrança</h3>
					<button type="button" onClick={close} className="btn">
						<i className="fas fa-times" />
					</button>
				</div>

				<div>
					<p>
						Você está prestes a colocar{' '}
						{batch?.type === 'ALL'
							? 'todas as'
							: batch?.identifiers.length}{' '}
						cobranças na fila de processamento.
						<br />
						Caso queira agendá-las, preencha o campo abaixo com a
						data e horário desejados. Caso seja feito o agendamento
						sem horário, o horário usado será 7:30.
						<br />
						Caso queira processá-las imediatamente, deixe o campo de
						data em branco.
					</p>

					<div className="form-group mb-0">
						<label>Data de agendamento (opcional)</label>
						<div className="row">
							<div className="col-md-6">
								<input
									ref={dateInput}
									type="date"
									className="form-control h-100"
								/>
							</div>
							<div className="col-md-6">
								<TimePicker ref={timeInput} />
							</div>
						</div>
					</div>
				</div>
			</ModalBody>
			<ModalFooter
				className="d-flex align-items-center justify-content-end"
				style={{ gap: '8px' }}
			>
				<Button
					disabled={isCharging}
					type="button"
					color="secondary"
					onClick={close}
				>
					Fechar
				</Button>
				<Button
					disabled={isCharging}
					type="button"
					color="primary"
					onClick={handleDoCharge}
				>
					Confirmar
				</Button>
			</ModalFooter>
		</Modal>
	);
});
