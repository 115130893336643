import { Card, CardBody, Container, Row } from 'reactstrap';

export const AccessDenied = () => {
	return (
		<Container fluid style={{ padding: '6rem 0' }}>
			<Row>
				<div className="col">
					<Card className="shadow">
						<CardBody className="text-center">
							<div
								className="mb-4 mx-auto"
								style={{ maxWidth: '400px' }}
							>
								<img
									className="w-100"
									alt="..."
									src="/images/access_denied.svg"
								/>
							</div>
							<h1>Acesso Negado</h1>
							<p>
								Você não possui permissão para visualizar essa
								página
							</p>
						</CardBody>
					</Card>
				</div>
			</Row>
		</Container>
	);
};
