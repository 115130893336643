import { useContext } from 'react';
import {
	CardFooter,
	Pagination,
	PaginationItem,
	PaginationLink
} from 'reactstrap';
import { ReportContext } from '../context';

export const ReportFooter = () => {
	const { pages, previousPage, nextPage, goToPage, currentPage } =
		useContext(ReportContext);

	return (
		<CardFooter className="py-4">
			<nav aria-label="...">
				<Pagination
					className="pagination justify-content-end mb-0"
					listClassName="justify-content-end mb-0"
				>
					<PaginationItem disabled={currentPage === 1}>
						<PaginationLink onClick={previousPage} tabIndex="-1">
							<i className="fas fa-angle-left" />
						</PaginationLink>
						<span className="sr-only">Anterior</span>
					</PaginationItem>
					{pages.map(pageIndex => (
						<PaginationItem
							key={pageIndex}
							className={
								currentPage === pageIndex ? 'active' : ''
							}
						>
							<PaginationLink onClick={() => goToPage(pageIndex)}>
								{pageIndex}
							</PaginationLink>
						</PaginationItem>
					))}
					<PaginationItem
						disabled={pages[pages.length - 1] === currentPage}
					>
						<PaginationLink onClick={nextPage}>
							<i className="fas fa-angle-right" />
							<span className="sr-only">Próximo</span>
						</PaginationLink>
					</PaginationItem>
				</Pagination>
			</nav>
		</CardFooter>
	);
};
