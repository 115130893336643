import { isAxiosError } from 'axios';
import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getHttpClient } from 'shared/api';
import { AccessDenied } from 'shared/components/AccessDenied';
import { useAcl } from 'shared/hooks/acl';
import useSWR from 'swr';

const DEFAULT_LIMIT = 100;

export const ReportContext = createContext({});

export const ReportContextProvider = ({ children, url, acl }) => {
	const { isPermitted } = useAcl();

	const [filters, setFilters] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [pages, setPages] = useState([1]);

	const httpClient = getHttpClient();

	const viewPermitted = isPermitted(acl.view);

	const { data, isLoading, mutate } = useSWR(
		{ url, ...filters, currentPage },
		async () => {
			if (!viewPermitted) return;

			const limit = DEFAULT_LIMIT;
			const offset = limit * (currentPage - 1);

			const params = new URLSearchParams({
				...filters,
				limit,
				offset
			});

			const { data } = await httpClient.get(
				`${url}?${params.toString()}`
			);

			return data;
		}
	);

	useEffect(() => {
		const buttons = [];

		for (let i = currentPage - 2; i <= pages; i++) {
			if (buttons.length >= 5) break;
			if (i <= 0) continue;
			buttons.push(i);
		}

		setPages(buttons);
	}, [data]);

	const handleExport = async type => {
		try {
			const params = new URLSearchParams({
				...filters,
				export: type
			});

			const { data } = await httpClient.get(
				`${url}?${params.toString()}`,
				{
					responseType: 'blob'
				}
			);

			window.open(URL.createObjectURL(data), '_blank');
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao exportar os dados, tente novamente mais tarde.'
			);
		}
	};

	const refresh = () => mutate();

	const nextPage = () => setCurrentPage(prev => prev + 1);
	const previousPage = () => setCurrentPage(prev => prev - 1);
	const goToPage = page => setCurrentPage(page);

	return (
		<ReportContext.Provider
			value={{
				data,
				isLoading,
				refresh,
				nextPage,
				previousPage,
				goToPage,
				currentPage,
				setCurrentPage,
				pages,
				setFilters,
				filters,
				handleExport,
				acl
			}}
		>
			{viewPermitted ? children : <AccessDenied />}
		</ReportContext.Provider>
	);
};
