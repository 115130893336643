import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getHttpClient } from 'shared/api';
import { useToast } from 'shared/hooks/toast';

export const useUserForm = id => {
	const { toast } = useToast();
	const navigate = useNavigate();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [acl, setAcl] = useState([]);

	const [isSaving, setIsSaving] = useState(false);
	const [isLoading, setIsLoading] = useState(true);

	const handleSubmit = async e => {
		try {
			e.preventDefault();

			setIsSaving(true);

			const httpClient = getHttpClient();
			const method = id ? httpClient.put : httpClient.post;
			await method(`/users${id ? `/${id}` : ''}`, {
				name,
				email,
				password,
				acl
			});

			toast.success(
				`Usuário ${id ? 'atualizado' : 'cadastrado'} com sucesso!`
			);

			return navigate('/admin/users');
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				`Houve um erro desconhecido ao ${
					id ? 'atualizar' : 'cadastrar'
				} usuário, tente novamente mais tarde.`
			);
		} finally {
			setIsSaving(false);
		}
	};

	const populateUser = async () => {
		try {
			if (!id) return null;

			const httpClient = getHttpClient();
			const { data } = await httpClient.get(`/users/${id}`);

			setName(data.name);
			setEmail(data.email);
			setAcl(data.acl);
		} catch {}
	};

	useEffect(() => {
		populateUser().then(() => setIsLoading(false));
	}, [id]);

	return {
		handleSubmit,
		isLoading,
		isSaving,
		name,
		setName,
		email,
		setEmail,
		password,
		setPassword,
		acl,
		setAcl
	};
};
