export const ChargeStatus = {
	VALIDATING: 'VALIDATING',
	WAITING: 'WAITING',
	PENDING: 'PENDING',
	PROCESSING: 'PROCESSING',
	DONE: 'DONE',
	ERROR: 'ERROR',
	SCHEDULED: 'SCHEDULED'
};

export const getChargeStatus = status => {
	switch (status) {
		case ChargeStatus.VALIDATING:
			return {
				label: 'Pendente',
				color: 'text-info',
				icon: 'fas fa-info-circle'
			};
		case ChargeStatus.WAITING:
			return {
				label: 'Pendente',
				color: 'text-info',
				icon: 'fas fa-info-circle'
			};
		case ChargeStatus.PENDING:
			return {
				label: 'Pendente',
				color: 'text-info',
				icon: 'fas fa-info-circle'
			};
		case ChargeStatus.PROCESSING:
			return {
				label: 'Processando',
				color: 'text-primary',
				icon: 'fas fa-hourglass-half'
			};
		case ChargeStatus.SCHEDULED:
			return {
				label: 'Agendado',
				color: 'text-primary',
				icon: 'fas fa-calendar'
			};
		case ChargeStatus.DONE:
			return {
				label: 'Concluído',
				color: 'text-success',
				icon: 'fas fa-check-circle'
			};
		case ChargeStatus.ERROR:
			return {
				label: 'Erro',
				color: 'text-danger',
				icon: 'fas fa-warning'
			};
	}
};
