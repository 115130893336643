import { useContext } from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { ReportContext } from '../context';
import { useAcl } from 'shared/hooks/acl';

export const ExportButton = () => {
	const { handleExport, acl: reportAcl } = useContext(ReportContext);
	const acl = useAcl();

	if (!acl.isPermitted(reportAcl.export)) return <></>;

	return (
		<UncontrolledDropdown>
			<DropdownToggle
				role="button"
				color="primary"
				onClick={e => e.preventDefault()}
			>
				Exportar
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu-arrow" right>
				<DropdownItem onClick={e => handleExport('CSV')}>
					CSV
				</DropdownItem>
				<DropdownItem onClick={e => handleExport('XLSX')}>
					XLSX
				</DropdownItem>
				<DropdownItem onClick={e => handleExport('PDF')}>
					PDF
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};
