import { ChargesReport } from 'components/Reports/Charges';
import { ReportContextProvider } from 'shared/components/reports/context';
import { AclResource } from 'shared/constants/user';

export const ChargesPage = () => {
	return (
		<ReportContextProvider
			url="/reports/charges"
			acl={{
				view: [
					AclResource.CHARGES_VIEW_ALL,
					AclResource.CHARGES_VIEW_OWN
				],
				export: AclResource.CHARGES_EXPORT
			}}
		>
			<ChargesReport />
		</ReportContextProvider>
	);
};
