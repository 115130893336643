import { ChargeStatus } from 'components/Reports/Charges/constants';
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

const Header = props => {
	return (
		<>
			<div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
				<Container fluid>
					<div className="header-body">
						{/* Card stats */}
						<Row className="justify-content-between">
							<Col
								style={{ cursor: 'pointer' }}
								onClick={() =>
									props.setStatus(ChargeStatus.DONE)
								}
								lg="6"
								xl="2"
							>
								<Card
									className={`card-stats mb-4 mb-xl-0 border-lg ${
										props.status === ChargeStatus.DONE
											? 'border-primary'
											: ''
									}`}
								>
									<CardBody>
										<Row>
											<div className="col">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Concluídos
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{props.done}
												</span>
											</div>
											<Col className="col-auto">
												<div className="icon icon-shape bg-success text-white rounded-circle shadow">
													<i className="fas fa-check" />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col
								style={{ cursor: 'pointer' }}
								onClick={() =>
									props.setStatus(ChargeStatus.ERROR)
								}
								lg="6"
								xl="2"
							>
								<Card
									className={`card-stats mb-4 mb-xl-0 border-lg  ${
										props.status === ChargeStatus.ERROR
											? 'border-primary'
											: ''
									}`}
								>
									<CardBody>
										<Row>
											<div className="col">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Erros
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{props.error}
												</span>
											</div>
											<Col className="col-auto">
												<div className="icon icon-shape bg-danger text-white rounded-circle shadow">
													<i className="fas fa-warning" />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col
								style={{ cursor: 'pointer' }}
								onClick={() =>
									props.setStatus(ChargeStatus.PROCESSING)
								}
								lg="6"
								xl="2"
							>
								<Card
									className={`card-stats mb-4 mb-xl-0 border-lg ${
										props.status === ChargeStatus.PROCESSING
											? 'border-primary'
											: ''
									}`}
								>
									<CardBody>
										<Row>
											<div className="col">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Processando
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{props.processing}
												</span>
											</div>
											<Col className="col-auto">
												<div className="icon icon-shape bg-primary text-white rounded-circle shadow">
													<i className="fas fa-hourglass-half" />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col
								style={{ cursor: 'pointer' }}
								onClick={() =>
									props.setStatus(ChargeStatus.SCHEDULED)
								}
								lg="6"
								xl="2"
							>
								<Card
									className={`card-stats mb-4 mb-xl-0 border-lg ${
										props.status === ChargeStatus.SCHEDULED
											? 'border-primary'
											: ''
									}`}
								>
									<CardBody>
										<Row>
											<div className="col">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Agendados
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{props.scheduled}
												</span>
											</div>
											<Col className="col-auto">
												<div className="icon icon-shape bg-primary text-white rounded-circle shadow">
													<i className="fas fa-calendar" />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col
								style={{ cursor: 'pointer' }}
								onClick={() =>
									props.setStatus(ChargeStatus.PENDING)
								}
								lg="6"
								xl="2"
							>
								<Card
									className={`card-stats mb-4 mb-xl-0 border-lg
										${props.status === ChargeStatus.PENDING ? ' border-primary' : ''}
									`}
								>
									<CardBody>
										<Row>
											<div className="col">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Pendentes
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{props.pending}
												</span>
											</div>
											<Col className="col-auto">
												<div className="icon icon-shape bg-info text-white rounded-circle shadow">
													<i className="fas fa-percent" />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Header;
