import { ReportContextProvider } from 'shared/components/reports/context';
import { ImportsReport } from 'components/Reports/Imports';
import { AclResource } from 'shared/constants/user';

export const ImportsPage = () => {
	return (
		<ReportContextProvider
			url="/reports/import-requests"
			acl={{
				view: AclResource.IMPORT_REQUESTS_VIEW,
				export: AclResource.IMPORT_REQUESTS_EXPORT
			}}
		>
			<ImportsReport />
		</ReportContextProvider>
	);
};
