import { AclResource } from 'shared/constants/user';

export const resources = [
	{
		name: 'Cobranças',
		resources: [
			{
				name: 'Arquivar cobranças',
				resource: AclResource.CHARGES_ARCHIVE
			},
			{
				name: 'Cobrar cobranças',
				resource: AclResource.CHARGES_CHARGE
			},
			{
				name: 'Criar cobranças',
				resource: AclResource.CHARGES_CREATE
			},
			{
				name: 'Exportar cobranças',
				resource: AclResource.CHARGES_EXPORT
			},
			{
				name: 'Importar cobranças',
				resource: AclResource.CHARGES_IMPORT
			},
			{
				name: 'Visualizar todas as cobranças',
				resource: AclResource.CHARGES_VIEW_ALL
			},
			{
				name: 'Visualizar suas próprias cobranças',
				resource: AclResource.CHARGES_VIEW_OWN
			}
		]
	},
	{
		name: 'Importações',
		resources: [
			{
				name: 'Exportar importações',
				resource: AclResource.IMPORT_REQUESTS_EXPORT
			},
			{
				name: 'Visualizar importações',
				resource: AclResource.IMPORT_REQUESTS_VIEW
			}
		]
	},

	{
		name: 'Usuários',
		resources: [
			{
				name: 'Exportar usuários',
				resource: AclResource.USERS_EXPORT
			},
			{
				name: 'Gerenciar usuários',
				resource: AclResource.USERS_MANAGE
			},
			{
				name: 'Visualizar usuários',
				resource: AclResource.USERS_VIEW
			}
		]
	}
];
