import { Button, Card, CardHeader, Container, Row, Table } from 'reactstrap';
import { getHttpClient } from 'shared/api';
import dayjs from 'dayjs';
import { ExportButton } from 'shared/components/reports/Export';
import { ReportFooter } from 'shared/components/reports/Footer';
import { ReportContext } from 'shared/components/reports/context';
import { useContext, useRef, useState } from 'react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { getImportRequestStatus } from './constants';

export const ImportsReport = () => {
	const httpClient = getHttpClient();

	const { data, isLoading, refresh } = useContext(ReportContext);
	const [isImporting, setIsImporting] = useState(false);
	const fileInputRef = useRef(null);

	const handleFileChange = async e => {
		try {
			setIsImporting(true);

			const file = e.target.files[0];

			if (!file) return;

			const formData = new FormData();
			formData.append('file', file);

			await httpClient.post('/import-requests', formData);
			toast.success('Arquivo importado com sucesso!');
			refresh();
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao importar arquivo, tente novamente mais tarde.'
			);
		} finally {
			fileInputRef.current.value = null;
			setIsImporting(false);
		}
	};

	return (
		<Container className="mt--7" fluid style={{ padding: '9rem 0' }}>
			<Row>
				<div className="col">
					<Card className="shadow">
						<CardHeader className="border-0 d-flex align-items-center justify-content-between">
							<h3 className="mb-0">Importações</h3>
							<div className="d-flex align-items-center">
								<Button
									disabled={isLoading}
									color="primary"
									size="md"
									onClick={refresh}
									type="button"
								>
									<i className="fas fa-sync-alt" />
								</Button>
								<ExportButton url="/reports/import-requests" />

								<input
									type="file"
									className="d-none"
									ref={fileInputRef}
									onChange={handleFileChange}
								/>
								<Button
									disabled={isLoading || isImporting}
									color="primary"
									size="md"
									type="button"
									onClick={() =>
										fileInputRef?.current.click()
									}
								>
									<input
										id="teste"
										type="file"
										className="d-none"
									/>
									Importar
								</Button>
							</div>
						</CardHeader>
						<Table
							className="align-items-center table-flush"
							responsive
						>
							<thead className="thead-light">
								<tr>
									<th scope="col">Linhas</th>
									<th scope="col">Status</th>
									<th scope="col">Importado por</th>
									<th scope="col">Importado em</th>
									<th scope="col" />
								</tr>
							</thead>
							<tbody>
								{(data?.data || []).map(row => (
									<tr
										key={row.id}
										className="position-relative"
									>
										<td>
											{row.processedLines}/{row.lines}
										</td>
										<td
											className={
												getImportRequestStatus(
													row.status
												).color
											}
										>
											<i
												className={`
														d-inline-block mr-1
														${getImportRequestStatus(row.status).icon}
													`}
											/>
											{
												getImportRequestStatus(
													row.status
												).label
											}
											<br />
											<small>
												{row.comment ? row.comment : ''}
											</small>
										</td>
										<td>
											{row.importedBy.name}
											<br />
											<small>
												{row.importedBy.email}
											</small>
										</td>
										<td>
											{dayjs(row.createdAt).format(
												'DD/MM/YYYY HH:mm'
											)}
										</td>
									</tr>
								))}
							</tbody>
						</Table>
						<ReportFooter />
					</Card>
				</div>
			</Row>
		</Container>
	);
};
