import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col
} from 'reactstrap';
import { getHttpClient } from 'shared/api';
import { isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { getUser } from 'shared/utils/user';
import { useToast } from 'shared/hooks/toast';

const Login = () => {
	const { toast } = useToast();

	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);

	const emailRef = useRef(null);
	const passwordRef = useRef(null);

	useEffect(() => {
		const user = getUser();
		if (user) navigate('/admin');
	}, []);

	const handleSubmit = async e => {
		e.preventDefault();

		setIsLoading(true);

		try {
			const httpClient = getHttpClient();

			const { data } = await httpClient.post('/login', {
				email: emailRef.current.value,
				password: passwordRef.current.value
			});

			localStorage.setItem('access_token', data.accessToken);
			localStorage.setItem('expires_at', data.expiresAt);

			toast.success('Login realizado com sucesso!');

			return navigate('/admin');
		} catch (e) {
			if (isAxiosError(e)) {
				if (e.response?.data) {
					return toast.error(e.response.data.message);
				}
			}

			toast.error(
				'Ocorreu um erro desconhecido ao realizar o login, tente novamente mais tarde.'
			);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Col lg="5" md="7">
				<Card className="bg-secondary shadow border-0">
					<CardBody className="px-lg-5 py-lg-5">
						<div className="text-center text-muted mb-4">
							<h2>Acessar painel</h2>
							<small>
								Digite suas credenciais no formúlário abaixo
							</small>
						</div>
						<Form onSubmit={handleSubmit} role="form">
							<FormGroup className="mb-3">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										innerRef={emailRef}
										placeholder="Email"
										type="email"
										autoComplete="new-email"
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										innerRef={passwordRef}
										placeholder="Password"
										type="password"
										autoComplete="new-password"
									/>
								</InputGroup>
							</FormGroup>
							<div className="text-center">
								<Button
									className="mt-4"
									disabled={isLoading}
									color="primary"
									type="submit"
								>
									Entrar
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default Login;
