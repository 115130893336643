import { LocalizationProvider } from '@mui/x-date-pickers';
import { ptBR } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import routes from 'routes.js';
import { getUser } from 'shared/utils/user';
import { EditUserPage } from 'pages/users/edit';

const Admin = props => {
	const user = getUser();
	if (!user) {
		window.location.href = '/auth/login';
	}

	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return (
					<Route
						path={prop.path}
						element={prop.component}
						key={key}
						exact
					/>
				);
			} else {
				return null;
			}
		});
	};

	const getBrandText = path => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props?.location?.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return 'Brand';
	};

	return (
		<LocalizationProvider
			localeText={
				ptBR.components.MuiLocalizationProvider.defaultProps.localeText
			}
			adapterLocale="pt-br"
			dateAdapter={AdapterDayjs}
		>
			<Sidebar {...props} routes={routes} />
			<div className="main-content">
				<AdminNavbar
					{...props}
					brandText={getBrandText(props?.location?.pathname)}
				/>
				<Routes>
					{getRoutes(routes)}
					<Route path="/users/edit" element={<EditUserPage />} />
					<Route
						path="*"
						element={<Navigate to="/admin/charges" replace />}
					/>
				</Routes>
				<Container fluid>
					<AdminFooter />
				</Container>
			</div>
		</LocalizationProvider>
	);
};

export default Admin;
