import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container, Row } from 'reactstrap';

import routes from 'routes.js';

const Auth = () => {
	useEffect(() => {
		document.body.classList.add('bg-default');
		return () => {
			document.body.classList.remove('bg-default');
		};
	}, []);

	const getRoutes = routes => {
		return routes.map((prop, key) => {
			if (prop.layout === '/auth') {
				return (
					<Route
						path={prop.path}
						element={prop.component}
						key={key}
						exact
					/>
				);
			} else {
				return null;
			}
		});
	};

	return (
		<div
			style={{ height: '100vh' }}
			className="d-flex align-items-center justify-content-center"
		>
			<Container>
				<Row className="justify-content-center">
					<Routes>
						{getRoutes(routes)}
						<Route
							path="*"
							element={<Navigate to="/auth/login" replace />}
						/>
					</Routes>
				</Row>
			</Container>
		</div>
	);
};

export default Auth;
