import {
	Card,
	CardHeader,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Table,
	Container,
	Row,
	Button,
	CardBody,
	Input,
	Label,
	FormGroup
} from 'reactstrap';

import Header from 'components/Headers/Header.js';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { RegisterChargeModal } from 'components/Modal/RegisterCharge/RegisterChargeModal';
import { getUser } from 'shared/utils/user';
import dayjs from 'dayjs';
import { ChargeDetailsModal } from 'components/Modal/ChargeDetails';
import { DoChargeModal } from 'components/Modal/DoCharge';
import { ChargeStatus, getChargeStatus } from './constants';
import { ReportContext } from 'shared/components/reports/context';
import { ReportFooter } from 'shared/components/reports/Footer';
import { ExportButton } from 'shared/components/reports/Export';
import { getHttpClient } from 'shared/api';
import { AclResource } from 'shared/constants/user';
import { useAcl } from 'shared/hooks/acl';

export const ChargesReport = () => {
	const { isPermitted } = useAcl();
	const [batchType, setBatchType] = useState('SPECIFIED');

	const httpClient = getHttpClient();

	const { refresh, data, isLoading, setFilters, filters } =
		useContext(ReportContext);

	const modalRef = useRef(null);
	const detailsModalRef = useRef(null);
	const doChargeModalRef = useRef(null);

	const [status, setStatus] = useState('');

	const [charges, setCharges] = useState([]);

	const fileInputRef = useRef(null);

	const [isImporting, setIsImporting] = useState(false);
	const [chargeSince, setChargeSince] = useState();
	const [chargeUntil, setChargeUntil] = useState();
	const [archived, setArchived] = useState('0');
	const [scheduledTo, setScheduledTo] = useState();
	const [search, setSearch] = useState('');

	const lastChangeRef = useRef(null);
	const handleSearch = e => {
		lastChangeRef.current = new Date().getTime();
		const value = e.currentTarget.value;

		setTimeout(() => {
			if (new Date().getTime() - lastChangeRef.current >= 800) {
				setSearch(value);
			}
		}, 800);
	};

	const user = getUser();

	const handleSpecifiedBatch = id => {
		if (!id && charges.length === 0) {
			toast.error('Selecione ao menos uma cobrança para cobrar.');
			return null;
		}

		return {
			type: 'SPECIFIED',
			identifiers: id ? [id] : charges
		};
	};

	const handleAllBatch = () => {
		return {
			type: 'ALL',
			filters
		};
	};

	const getBatch = id => {
		return batchType === 'ALL'
			? handleAllBatch()
			: handleSpecifiedBatch(id);
	};

	const handleFileChange = async e => {
		try {
			setIsImporting(true);

			const file = e.target.files[0];

			if (!file) return;

			const formData = new FormData();
			formData.append('file', file);

			await httpClient.post('/import-requests', formData);
			toast.success('Arquivo importado com sucesso!');
			refresh();
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao importar arquivo, tente novamente mais tarde.'
			);
		} finally {
			fileInputRef.current.value = null;
			setIsImporting(false);
		}
	};

	const handleChargeCheckboxChange = e => {
		const isChecked = e.target.checked;

		if (isChecked) {
			return setCharges(prev => [...prev, e.target.value]);
		}

		setCharges(prev => prev.filter(charge => charge !== e.target.value));
	};

	const handleArchive = async (id = null) => {
		try {
			const batch = getBatch(id);
			if (!batch) return;

			await httpClient.patch('/charges/archive', {
				batch
			});

			refresh();

			toast.success('Cobranças arquivadas com sucesso!');
		} catch (e) {
			console.log(e);
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao arquivar cobranças, tente novamente mais tarde.'
			);
		}
	};

	const handleUnarchive = async (id = null) => {
		try {
			const batch = getBatch(id);
			if (!batch) return;

			await httpClient.patch('/charges/unarchive', {
				batch
			});

			refresh();

			toast.success('Cobranças desarquivadas com sucesso!');
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao desarquivar cobranças, tente novamente mais tarde.'
			);
		}
	};

	const handleFilters = () => {
		const obj = {};

		if (chargeSince) {
			obj.chargeSince = chargeSince.toISOString().split('T')[0];
		}

		if (chargeUntil) {
			obj.chargeUntil = chargeUntil.toISOString().split('T')[0];
		}

		if (status) {
			obj['status'] = status;
		}

		if (scheduledTo) {
			obj['scheduledTo'] = dayjs(scheduledTo)
				.add(3, 'h')
				.format('YYYY-MM-DD');
		}

		if (search) {
			obj['search'] = search;
		}

		if (archived !== '') {
			obj['archived'] = Number(archived);
		}

		setFilters(obj);
	};

	useEffect(() => {
		handleFilters();
	}, [chargeSince, chargeUntil, archived, scheduledTo, search, status]);

	const handleBatchTypeChange = type => {
		setBatchType(type);
	};

	return (
		<>
			<Header
				status={status}
				setStatus={setStatus}
				done={data?.metadata?.counts?.done || 0}
				processing={data?.metadata?.counts?.processing || 0}
				pending={data?.metadata?.counts?.pending || 0}
				error={data?.metadata?.counts?.error || 0}
				scheduled={data?.metadata?.counts?.scheduled || 0}
			/>
			<Container className="mt--7" fluid>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-0 d-flex align-items-center justify-content-between">
								<h3 className="mb-0">Transferências</h3>
								<div className="d-flex align-items-center">
									<Button
										disabled={isLoading}
										color="primary"
										size="md"
										onClick={refresh}
										type="button"
									>
										<i className="fas fa-sync-alt" />
									</Button>
									<ExportButton />
									{isPermitted(AclResource.CHARGES_CHARGE) ? (
										<Button
											disabled={isLoading}
											color="primary"
											size="md"
											type="button"
											onClick={() =>
												doChargeModalRef.current?.show(
													getBatch()
												)
											}
										>
											Cobrar
										</Button>
									) : (
										<></>
									)}

									{isPermitted(AclResource.CHARGES_CREATE) ? (
										<Button
											disabled={isLoading}
											color="primary"
											size="md"
											onClick={() =>
												modalRef.current?.show()
											}
											type="button"
										>
											Nova cobrança
										</Button>
									) : (
										<></>
									)}

									<input
										type="file"
										className="d-none"
										ref={fileInputRef}
										onChange={handleFileChange}
									/>
									{isPermitted(AclResource.CHARGES_IMPORT) ? (
										<Button
											disabled={isLoading || isImporting}
											color="primary"
											size="md"
											type="button"
											onClick={() =>
												fileInputRef?.current.click()
											}
										>
											<input
												id="teste"
												type="file"
												className="d-none"
											/>
											Importar
										</Button>
									) : (
										<></>
									)}

									{isPermitted(
										AclResource.CHARGES_ARCHIVE
									) ? (
										<UncontrolledDropdown
											direction={'down'}
										>
											<DropdownToggle
												color="primary"
												caret
											>
												Ações
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem
													onClick={() =>
														handleArchive()
													}
													disabled={
														!charges.length &&
														batchType ===
															'SPECIFIED'
													}
												>
													Arquivar
												</DropdownItem>
												<DropdownItem
													disabled={
														!charges.length &&
														batchType ===
															'SPECIFIED'
													}
													onClick={() =>
														handleUnarchive()
													}
												>
													Desarquivar
												</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									) : (
										<></>
									)}
								</div>
							</CardHeader>
							<CardBody className="mt-n4">
								<div className="d-flex" style={{ gap: '16px' }}>
									<FormGroup style={{ flexGrow: 1 }}>
										<Label size="sm">
											Pesquise pelo cobrador, PIX ou
											importador
										</Label>
										<Input
											onChange={handleSearch}
											size={'md'}
											name="search"
										/>
									</FormGroup>
									<FormGroup>
										<Label size="sm">
											Vencimento a partir de
										</Label>
										<Input
											type="date"
											size={'md'}
											name="chargeSince"
											onChange={e =>
												setChargeSince(
													e.currentTarget.valueAsDate
												)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Label size="sm">Vencimento até</Label>
										<Input
											type="date"
											size={'md'}
											name="chargeUntil"
											onChange={e =>
												setChargeUntil(
													e.currentTarget.valueAsDate
												)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Label size="sm">Agendado para</Label>
										<Input
											type="date"
											size={'md'}
											name="scheduledTo"
											onChange={e =>
												setScheduledTo(
													e.currentTarget.valueAsDate
												)
											}
										/>
									</FormGroup>
									<FormGroup>
										<Label size="sm">Arquivadas</Label>
										<Input
											type="select"
											value={archived}
											onChange={e =>
												setArchived(
													e.currentTarget.value
												)
											}
										>
											<option value="">Todos</option>
											<option value="1">
												Arquivadas
											</option>
											<option value="0">
												Não arquivadas
											</option>
										</Input>
									</FormGroup>
								</div>
								<Table
									className="align-items-center table-flush"
									responsive
								>
									<thead className="thead-light">
										<tr>
											<th scope="col">
												<div className="d-flex align-items-center justify-content-center">
													<div className="custom-control custom-control-alternative custom-checkbox">
														<input
															className="custom-control-input"
															id={`checkbox_batch_all`}
															type="checkbox"
															onChange={e =>
																handleBatchTypeChange(
																	e
																		.currentTarget
																		.checked
																		? 'ALL'
																		: 'SPECIFIED'
																)
															}
															checked={
																batchType ===
																'ALL'
															}
														/>
														<label
															className="custom-control-label font-weight-bold"
															htmlFor={`checkbox_batch_all`}
														></label>
													</div>
												</div>
											</th>
											<th scope="col">Pagador</th>
											<th scope="col">Valor</th>
											<th scope="col">Vencimento</th>
											<th scope="col">Status</th>
											<th scope="col">Importado por</th>
											<th scope="col">Cobrado por</th>
											<th scope="col" />
										</tr>
									</thead>
									<tbody>
										{(data?.data || []).map(row => (
											<tr
												key={row.id}
												className="position-relative"
												data-selected={charges.some(
													charge => charge === row.id
												)}
											>
												<td>
													<div className="d-flex flex-column left-0 position-absolute align-items-center justify-content-center mt-1">
														{row.warnings.length >
															0 && (
															<span
																title={
																	row
																		.warnings[0]
																		.message
																}
															>
																<i className="fas fa-warning text-yellow"></i>
															</span>
														)}
														{row.isArchived && (
															<span title="Cobrança arquivada">
																<i class="fa-solid fa-box-archive text-primary"></i>
															</span>
														)}
													</div>

													<div className="d-flex align-items-center justify-content-center">
														<div className="custom-control custom-control-alternative custom-checkbox">
															<input
																className="custom-control-input"
																id={`checkbox_${row.id}`}
																type="checkbox"
																onClick={
																	handleChargeCheckboxChange
																}
																checked={
																	!!(
																		batchType ===
																			'ALL' ||
																		charges.find(
																			charge =>
																				charge ===
																				row.id
																		)
																	)
																}
																disabled={
																	batchType ===
																	'ALL'
																}
																value={row.id}
															/>
															<label
																className="custom-control-label"
																htmlFor={`checkbox_${row.id}`}
															></label>
														</div>
													</div>
												</td>
												<td>
													{row.payer ? (
														<>
															{row.payer.name
																.length > 30 ? (
																<span
																	title={
																		row
																			.payer
																			.name
																	}
																>
																	{row.payer.name.substring(
																		0,
																		30
																	) + '...'}
																</span>
															) : (
																row.payer.name
															)}
															<br />
															<small>
																{
																	row.payer
																		.document
																}
															</small>
														</>
													) : (
														'-'
													)}
												</td>
												<td>
													{(
														row.amount / 100
													).toLocaleString('pt-BR', {
														style: 'currency',
														currency: 'BRL'
													})}
													<br />
													<small>
														PIX: {row.pix}
													</small>
												</td>
												<td>
													{dayjs(
														row.chargeDate
													).format('DD/MM/YYYY')}
												</td>
												<td
													className={
														getChargeStatus(
															row.status
														).color
													}
												>
													<i
														className={`
														d-inline-block mr-1
														${getChargeStatus(row.status).icon}
													`}
													/>
													{
														getChargeStatus(
															row.status
														).label
													}
													{row.status ===
													'SCHEDULED' ? (
														<>
															<br />
															<small>
																{dayjs(
																	row.scheduledTo
																).format(
																	'DD/MM/YYYY HH:mm'
																)}
															</small>
														</>
													) : (
														<></>
													)}

													<small
														title={
															row.comment || ''
														}
														className="d-block"
													>
														{row.comment
															? row.comment
																	.length > 40
																? row.comment.substring(
																		0,
																		40
																  ) + '...'
																: row.comment
															: ''}
													</small>
												</td>
												<td>
													{row.createdBy.name}
													<br />
													<small>
														{row.createdBy.email}
													</small>
												</td>
												<td>
													{row.chargedBy ? (
														<>
															{row.chargedBy.name}
															<br />
															<small>
																{
																	row
																		.chargedBy
																		.email
																}
															</small>
														</>
													) : (
														'-'
													)}
												</td>
												<td className="text-right">
													<UncontrolledDropdown>
														<DropdownToggle
															className="btn-icon-only text-light"
															role="button"
															size="sm"
															color=""
															onClick={e =>
																e.preventDefault()
															}
														>
															<i className="fas fa-ellipsis-v" />
														</DropdownToggle>
														<DropdownMenu
															className="dropdown-menu-arrow"
															right
														>
															<DropdownItem
																onClick={e =>
																	detailsModalRef.current.show(
																		row
																	)
																}
															>
																Detalhes
															</DropdownItem>
															{user.isPermitted(
																AclResource.CHARGES_CHARGE
															) &&
																[
																	ChargeStatus.ERROR,
																	ChargeStatus.WAITING,
																	ChargeStatus.VALIDATING,
																	ChargeStatus.PENDING
																].find(
																	status =>
																		status ===
																		row.status
																) && (
																	<DropdownItem
																		onClick={e =>
																			modalRef.current.show(
																				{
																					id: row.id,
																					pix: row.pix,
																					payer:
																						row
																							.payer
																							?.document ||
																						null,
																					amount: (
																						row.amount /
																						100
																					).toLocaleString(
																						'pt-BR',
																						{
																							style: 'currency',
																							currency:
																								'BRL'
																						}
																					)
																				}
																			)
																		}
																	>
																		Corrigir
																	</DropdownItem>
																)}
															{user.isPermitted(
																AclResource.CHARGES_ARCHIVE
															) ? (
																<>
																	{row.isArchived ? (
																		<DropdownItem
																			onClick={e =>
																				handleUnarchive(
																					row.id
																				)
																			}
																		>
																			Desarquivar
																		</DropdownItem>
																	) : (
																		<DropdownItem
																			onClick={e =>
																				handleArchive(
																					row.id
																				)
																			}
																		>
																			Arquivar
																		</DropdownItem>
																	)}{' '}
																</>
															) : (
																<></>
															)}
														</DropdownMenu>
													</UncontrolledDropdown>
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</CardBody>
							<ReportFooter />
						</Card>
					</div>
				</Row>
			</Container>
			<RegisterChargeModal refresh={refresh} ref={modalRef} />
			<ChargeDetailsModal ref={detailsModalRef} />
			<DoChargeModal
				charges={charges}
				setCharges={setCharges}
				ref={doChargeModalRef}
			/>
		</>
	);
};
