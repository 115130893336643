export const ImportRequestStatus = {
	PENDING: 'PENDING',
	PROCESSING: 'PROCESSING',
	DONE: 'DONE'
};

export const getImportRequestStatus = status => {
	switch (status) {
		case ImportRequestStatus.PENDING:
			return {
				label: 'Pendente',
				color: 'text-info',
				icon: 'fas fa-info-circle'
			};
		case ImportRequestStatus.PROCESSING:
			return {
				label: 'Processando',
				color: 'text-primary',
				icon: 'fas fa-hourglass-half'
			};
		case ImportRequestStatus.DONE:
			return {
				label: 'Concluído',
				color: 'text-success',
				icon: 'fas fa-check-circle'
			};
	}
};
