import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
	Button,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import { getHttpClient } from 'shared/api';

import { toast } from 'react-toastify';

import useSWR from 'swr';
import { isAxiosError } from 'axios';

export const RegisterChargeModal = forwardRef((props, ref) => {
	const id = useRef(null);

	const [pix, setPix] = useState('');
	const [payer, setPayer] = useState('');
	const [amount, setAmount] = useState('');

	const [isOpen, setIsOpen] = useState(false);

	const httpClient = getHttpClient();
	const { data } = useSWR({ key: 'payers' }, async () => {
		const { data } = await httpClient.get('/payers');
		return data;
	});

	const show = props => {
		id.current = props?.id || null;

		if (props) {
			setPix(props.pix);
			setPayer(props.payer);
			setAmount(props.amount);
		} else {
			setPix('');
			setPayer(data?.data?.[0]?.document);
			setAmount('');
		}

		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
	};

	useImperativeHandle(ref, () => ({
		show,
		close
	}));

	const handleSubmit = async e => {
		try {
			e.preventDefault();

			if (!payer) {
				return toast.error('Você deve selecionar um pagador');
			}
			if (!pix) {
				return toast.error('Você deve informar o PIX de destino');
			}
			if (!amount) {
				return toast.error(
					'Você deve selecionar o valor a ser transferido.'
				);
			}

			await httpClient.post(
				`/charges${id.current ? `/${id.current}` : ''}`,
				{
					pix,
					amount,
					payer: {
						document: payer
					},
					chargeDate: new Date()
				}
			);

			toast.success('Cobrança registrada com sucesso!');
			close();
			props.refresh();
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Houve um erro desconhecido ao registrar cobrança, tente novamente mais tarde.'
			);
		}
	};

	return (
		<Modal isOpen={isOpen}>
			<form onSubmit={handleSubmit}>
				<ModalBody>
					<div className="d-flex align-items-center justify-content-between mb-4">
						<h3>Registrar cobrança</h3>
						<button type="button" onClick={close} className="btn">
							<i className="fas fa-times" />
						</button>
					</div>

					<FormGroup className="mb-3">
						<Label>Pagador</Label>
						<Input
							type="select"
							value={payer}
							onChange={e => setPayer(e.target.value)}
						>
							{(data?.data || []).map(payer => (
								<option value={payer.document}>
									{payer.name} - {payer.document}
								</option>
							))}
						</Input>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label>Valor</Label>
						<Input
							value={amount}
							onChange={e => setAmount(e.target.value)}
							placeholder="Valor"
							type="text"
						/>
					</FormGroup>
					<FormGroup className="mb-3">
						<Label>PIX</Label>
						<Input
							value={pix}
							onChange={e => setPix(e.target.value)}
							placeholder="PIX"
							type="text"
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter
					className="d-flex align-items-center justify-content-end"
					style={{ gap: '8px' }}
				>
					<Button type="button" color="secondary" onClick={close}>
						Fechar
					</Button>
					<Button type="submit" color="primary">
						Salvar
					</Button>
				</ModalFooter>
			</form>
		</Modal>
	);
});
