import { getUser } from 'shared/utils/user';

export const useAcl = () => {
	const user = getUser();

	const isPermitted = resource => {
		if (Array.isArray(resource)) {
			return resource.some(r => user.isPermitted(r));
		}

		return user.isPermitted(resource);
	};

	return { isPermitted };
};
