export const UserStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE'
};

export const getRoleStatus = status => {
	switch (status) {
		case UserStatus.ACTIVE:
			return {
				label: 'Ativo',
				color: 'text-success',
				icon: 'fas fa-info-circle'
			};
		case UserStatus.INACTIVE:
			return {
				label: 'Inativo',
				color: 'text-danger',
				icon: 'fas fa-info-circle'
			};
	}
};
