import {
	Card,
	CardHeader,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Table,
	Container,
	Row,
	Button,
	FormGroup,
	Label,
	Input,
	CardBody
} from 'reactstrap';
import { ExportButton } from 'shared/components/reports/Export';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useContext, useEffect, useRef, useState } from 'react';
import { ReportContext } from 'shared/components/reports/context';
import { getRoleStatus } from './constants';
import { ReportFooter } from 'shared/components/reports/Footer';
import { getHttpClient } from 'shared/api';
import { AclResource } from 'shared/constants/user';
import { useAcl } from 'shared/hooks/acl';
import { useNavigate } from 'react-router-dom';

export const UsersReport = () => {
	const navigate = useNavigate();
	const { isPermitted } = useAcl();

	const httpClient = getHttpClient();

	const { data, isLoading, refresh, setFilters } = useContext(ReportContext);

	const [search, setSearch] = useState('');

	const lastChangeRef = useRef(null);
	const handleSearch = e => {
		lastChangeRef.current = new Date().getTime();
		const value = e.currentTarget.value;

		setTimeout(() => {
			if (new Date().getTime() - lastChangeRef.current >= 800) {
				setSearch(value);
			}
		}, 800);
	};

	useEffect(() => {
		setFilters({ search });
	}, [search]);

	const handleRemove = async id => {
		if (!window.confirm('Tem certeza que deseja inativar este usuário?')) {
			return;
		}

		try {
			await httpClient.delete(`/users/${id}`);

			toast.success('Usuário inativado com sucesso!');
			refresh();
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao inativar o usuário, tente novamente mais tarde.'
			);
		}
	};

	const handleRecover = async id => {
		if (!window.confirm('Tem certeza que deseja reativar este usuário?')) {
			return;
		}

		try {
			await httpClient.patch(`/users/${id}/recover`);

			toast.success('Usuário reativado com sucesso!');
			refresh();
		} catch (e) {
			if (isAxiosError(e)) {
				return toast.error(e.response.data.message);
			}

			toast.error(
				'Ocorreu um erro desconhecido ao reativar o usuário, tente novamente mais tarde.'
			);
		}
	};

	const toEdit = id => navigate(`/admin/users/edit${id ? `?id=${id}` : ''}`);

	return (
		<>
			<Container fluid style={{ padding: '6rem 0' }}>
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-0 d-flex align-items-center justify-content-between">
								<h3 className="mb-0">Usuários</h3>
								<div className="d-flex align-items-center">
									<Button
										disabled={isLoading}
										color="primary"
										size="md"
										onClick={refresh}
										type="button"
									>
										<i className="fas fa-sync-alt" />
									</Button>

									<ExportButton url="/reports/users" />

									{isPermitted(AclResource.USERS_MANAGE) ? (
										<Button
											disabled={isLoading}
											color="primary"
											size="md"
											type="button"
											onClick={() => toEdit()}
										>
											Novo usuário
										</Button>
									) : (
										<></>
									)}
								</div>
							</CardHeader>
							<CardBody className="mt-n4">
								<FormGroup style={{ flexGrow: 1 }}>
									<Label size="sm">
										Pesquise pelo nome ou email
									</Label>
									<Input
										onChange={handleSearch}
										size={'sm'}
										name="search"
									/>
								</FormGroup>
								<Table
									className="align-items-center table-flush"
									responsive
								>
									<thead className="thead-light">
										<tr>
											<th scope="col">Nome</th>
											<th scope="col">Email</th>
											<th scope="col">Status</th>
											<th scope="col" />
										</tr>
									</thead>
									<tbody>
										{(data?.data || []).map(row => (
											<tr key={row.id}>
												<td>{row.name}</td>
												<td>{row.email}</td>
												<td
													className={
														getRoleStatus(
															row.status
														).color
													}
												>
													<i
														className={`
														d-inline-block mr-1
														${getRoleStatus(row.status).icon}
													`}
													/>
													{
														getRoleStatus(
															row.status
														).label
													}
												</td>
												<td className="text-right">
													{isPermitted(
														AclResource.USERS_MANAGE
													) ? (
														<UncontrolledDropdown>
															<DropdownToggle
																className="btn-icon-only text-light"
																role="button"
																size="sm"
																color=""
																onClick={e =>
																	e.preventDefault()
																}
															>
																<i className="fas fa-ellipsis-v" />
															</DropdownToggle>
															<DropdownMenu
																className="dropdown-menu-arrow"
																right
															>
																<DropdownItem
																	onClick={() =>
																		toEdit(
																			row.id
																		)
																	}
																>
																	Editar
																</DropdownItem>
																<div className="dropdown-divider"></div>
																{row.status ===
																'ACTIVE' ? (
																	<DropdownItem
																		onClick={e =>
																			handleRemove(
																				row.id
																			)
																		}
																	>
																		Inativar
																	</DropdownItem>
																) : (
																	<DropdownItem
																		onClick={e =>
																			handleRecover(
																				row.id
																			)
																		}
																	>
																		Reativar
																	</DropdownItem>
																)}
															</DropdownMenu>
														</UncontrolledDropdown>
													) : (
														<></>
													)}
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							</CardBody>
							<ReportFooter />
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};
